import {
    DatePicker, Button, Col, Input, Layout, message, Row, Card, Select, Empty, Tooltip, Checkbox, Pagination, Tag
} from 'antd';
import { LikeOutlined, StopOutlined } from '@ant-design/icons';
import React from 'react';
import NavBar from '../components/header';
import {IMSV2SearchAPI, IMSV2BatchUpdateAPI} from '../apis/ims';
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class IMSAdmin extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            duplicate_removal_v2: true,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            input_image_url: null,
            image_upload_loading: false,
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            aws_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            ims_search_api_running: false,
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            ims_batch_edit_api_running: false,
            ims_search_api_response: null,
            allowed_admin_users: [
                "Ritesh Ramnarayan Pallod",
                "Pradeep M",
                "Vimal Tripathi",
                "Uddipan Saikia",
                "Sushant Jaggi",
                "Ian Anderson",
                "Paul Duff",
                "Alankrita Tannu",
                "Likina Nayak",
                "Gavaskar S",
                "Somashekara N S",
                "Shashi Kiran Manjunath",
                "Arvind Satrick",
                "Alisha Chatterjee",
                "Shalin Jacob",
                "Chirag Anirudh S",
            ],
            search_date: '',
            use_elastic_search_index: true,
            type: null,
            type_options: ["LS", "raw", "TV"],
            all: true,
            keywords: false,
            uploaded: false,
            images: [],
            title: null,
            image_url : "",
            partner_ids: [],
            partner_id_options: {
                "VIVO": "feb4e5ad0039fee86e4870dd3ca88c28a8ee1a1e",
                "VIVO_API": "d4b9c453aedd185aceb77c12ed0f18ac",
                "OPPO": "d1f143bd74d8977c781204281564a93f",
                "OPPO_API" : "b27cbb02e015198844783efe6db1d580",
                "TRANSSION": "b366fc7948b55d41976cf3014f616f6f",
            },
            regions: ["in", "id", "us", "br", "co", "mx", "vn", "th", "jp", "ph"],
            statuses: ["approved", "NONE"],
            statuses_options: ["approved", "blacklisted", "blacklisted_for_ls", "NONE"],
            statuses_color_options: {
                "approved": "green",
                "blacklisted": "red",
                "blacklisted_for_ls": "purple",
                "NONE": "grey",
                "": "orange",
                " ": "yellow",
            },
            source_name_search_str: null,
            last_used_at_in_days: null,
            last_used_at_in_days_options: [2, 10, 15, 30, 90, 120, 180],
            partner_id_options_keys: [],
            max_count :250,
            // pagination related
            currentPage: 1,
            pageSize: 18,
            offset: 0,
            edited_images_map: {},
        };
        for (var key in this.state.partner_id_options) {
            this.state.partner_id_options_keys.push(key);
        }

        this.imageManagementSearch = this.imageManagementSearch.bind(this);
      }
    
      getDaysSinceLastUsed(dateString) {
        const lastUsedDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate - lastUsedDate);
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      
        return daysDifference;
      }
      filterImages() {
        if (!Array.isArray(this.state.images)) {
            console.warn("Props images is not an array", this.state.images);
            return;
        }

        let filteredImages = [...this.state.images]; // start with all images

        if (this.state.all) {
            console.log("Inside all")
            // if all is selected, use all images without filtering
            this.setState({ images: filteredImages });
            return;
        }

        if (this.state.keywords) {
            console.log("Inside keywords")
            filteredImages = filteredImages.filter(img => img.keyword === true);
        }

        if (this.state.uploaded) {
            console.log("Inside uploaded")
            filteredImages = filteredImages.filter(img => img.spec && img.spec.is_uploaded === true);
        }

        this.setState({ images: filteredImages });
        console.log("Filtered images",this.state.images)
    }
      imageManagementSearch = async () => {
        message.loading("Fetching Images..", 0)
        this.setState({
            currentPage: 1,
            images: [],
            ims_search_api_response: null,
            edited_images_map: {},
        })
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": this.state.title,
                    "max_count": this.state.max_count,
                    "filter_images": false,
                }
            }
            if (this.state.statuses) {
                payload["params"]["statuses"] = this.state.statuses
            }
            if (this.state.partner_ids) {
                payload["params"]["partner_ids"] = this.state.partner_ids
            }
            if (this.state.source_name_search_str) {
                payload["params"]["source_name_search_str"] = this.state.source_name_search_str
            }
            if (this.state.last_used_at_in_days) {
                payload["params"]["last_used_at_in_days"] = this.state.last_used_at_in_days
            }
            if (this.state.type) {
                payload["params"]["type"] = this.state.type
            }
            if (this.state.image_url && this.state.image_url !== ""){
                payload["params"]["image_url"] = this.state.image_url
            }
            if (this.state.search_date && this.state.search_date !== ""){
                payload["params"]["search_date"] = this.state.search_date
            }
            try {
                IMSV2SearchAPI(payload, token).then((response) => {
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Image Search Complete!")
                    }
                    this.setState({
                        ims_search_api_running: false,
                        ims_search_api_response: response,
                        images: response.message.images,
                        offset: 0,
                    }, () => {
                        // Callback function after state update
                        this.filterImages();
                    });
                })
            }
            catch (error) {
                console.log(error)
                message.destroy()
                message.error("Failed to fetch images. Try again?")
                this.setState({
                    ims_search_api_running: false,
                })
            }    
        })
    }

    imsBatchEdit = async (mark_rest_as_approved=false) => {
        message.loading("Submitting batch edits", 0)
        this.setState({
            ims_batch_edit_api_running: true,
        })
        this.props.refreshIdToken().then((token) => {
            const X = (this.state.currentPage - 1) * this.state.pageSize; // Starting index
            const N = this.state.pageSize; // Number of elements to retrieve
            console.log("BATCHEDIT::X: ", X)
            console.log("BATCHEDIT::N: ", N)
            console.log("BATCHEDIT::this.state.edited_images_map", this.state.edited_images_map)
            const temp_packets = [];
            for (let i = X; i < X + N && i < this.state.images.length; i++) {
                const image_id = this.state.images[i].id;
                console.log("BATCHEDIT::i: ", i)
                console.log("BATCHEDIT::image_id: ", image_id)
                const value = this.state.edited_images_map[image_id];
                if (value) {
                    temp_packets.push(value);
                } else if (mark_rest_as_approved && !value && (this.state.images[i].status === null || this.state.images[i].status === "")) {
                    console.log("marking " + image_id + " as approved")
                    temp_packets.push({"id": image_id, "status": "approved"});
                }
            }
            console.log("BATCHEDIT::temp_packets: ", temp_packets)
            const payload = {
                packets: temp_packets
            }
            console.log("updated stuff: ", payload)
            try {
                IMSV2BatchUpdateAPI(payload, token).then((response) => {
                    if(response.status==false){
                        message.destroy()
                        message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                    }else{
                        message.destroy()
                        message.success("Edits made!")
                    }
                 })
                 this.setState({
                    ims_batch_edit_api_running: false,
                 })
            }
            catch (error) {
                console.log(error)
                message.destroy()
                message.error("Failed to update images. Try again?")
                this.setState({
                    ims_batch_edit_api_running: false,
                })
            }
        })
    }

    onDateChange = (date, dateString) => {
        this.setState({
          search_date: dateString // Update the state with the formatted date string
        });
      }
    
    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {

        const createForm = (
            <div>
                <Layout>
            <Content>
            <Row>
                <h2>IMS Admin Portal</h2>
            </Row>
            <Row>
            <Col span={10}>
            <h3> Search Query</h3>
            <Input placeholder='Search...' onChange={(e)=>{
                this.setState({
                    title: e.target.value
                })
            }}
            onPressEnter={
                !this.state.ims_search_api_running ? this.imageManagementSearch : undefined
              }
            />
            </Col>
            <Col>
            <h3> Type</h3>
                <Tooltip trigger={['focus']} title={"Any specific type"} placement="topLeft">
                    <Select
                        placeholder="Type"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.type}
                        allowClear={true}
                        onChange={(e)=>{
                                this.setState({
                                    type: e
                                    })
                                }}
                        >
                        {this.state.type_options.map((e) => {
                            return <Option
                                value={e}></Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Partner id</h3>  
                <Tooltip trigger={['focus']} title={"Any specific Partner id?"} placement="topLeft">
                    <Select
                        mode="multiple"
                        placeholder="Specific Partner id?"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.partner_ids}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                    partner_ids: e
                                })
                            }}
                        >
                        {this.state.partner_id_options_keys.map(key => {
                            return <Option value={this.state.partner_id_options[key]}>{key}</Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Source</h3>  
                <Tooltip trigger={['focus']} title={"Any specific source"} placement="topLeft">
                <Input placeholder='Search...'
                    onChange={(e)=>{
                        this.setState({
                            source_name_search_str: e.target.value
                            })
                    }}
                />
                </Tooltip>
            </Col>
            <Col span={3}>
            <h3> Status</h3>  
                <Tooltip trigger={['focus']} title={"Any specific status"} placement="topLeft">
                    <Select
                        mode="multiple"
                        placeholder="Status"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.statuses}
                        allowClear={true}
                        onChange={(e)=>{
                                this.setState({
                                    statuses: e
                                    })
                                }}
                        >
                        {this.state.statuses_options.map((e) => {
                            return <Option
                                value={e}></Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span={2}>
            <h3> Filter Last X days</h3>
                <Tooltip trigger={['focus']} title={"Filtering on last X days"} placement="topLeft">
                    <Select
                        placeholder="last X days"
                        style={{
                                width: '100%',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                                }}
                        value={this.state.last_used_at_in_days}
                        allowClear={true}
                        onChange={(e)=>{
                            this.setState({
                                last_used_at_in_days: e
                                })
                            }}
                        >
                        {this.state.last_used_at_in_days_options.map((e) => {
                            return <Option
                                value={e}></Option>
                        })}
                    </Select>
                </Tooltip>
            </Col>
            <Col span ={2}>
                <h3> Filter on Search Result</h3>
                    <Row span={6}>
                        <Checkbox 
                        defaultChecked={true}
                        onChange={(e) => {
                            this.setState({
                                all: e.target.checked
                            })
                        }}>All</Checkbox>
                    </Row>
                    <Row span={6}>
                        <Checkbox onChange={(e) => {
                            this.setState({
                                keywords: e.target.checked
                            })
                        }}>Keywords</Checkbox>
                    </Row>
                    <Row span={6}>
                        <Checkbox onChange={(e) => {
                            this.setState({
                                uploaded: e.target.checked
                            })
                        }}>Uploaded</Checkbox>
                    </Row>
            </Col>
            <Col span={2}>
            <Row>
                <h4>Select Date</h4>
                <DatePicker 
                    onChange={this.onDateChange} 
                    format="YYYY-MM-DD" // Specify the format you want
                />
            </Row>
    
            </Col>            
            <Col span={4}>
            <Row>

            <h3> Reverse Search </h3>
                    <UploadButton
                        signin_name={this.props.username}
                        upload_loading={this.state.image_upload_loading}
                        upload_endpoint={this.state.upload_endpoint}
                        id_token={this.props.idToken}
                        onImUploadChange={(e) => {
                            if (e.file.status == 'done') {
                                message.success(' Uploaded!')
                                var temp_video_upload_url = e.file.response.image_url
                                this.setState({
                                    image_url: temp_video_upload_url
                                })
                            } else if (e.file.status == 'removed') {
                                this.setState({
                                    image_url: ''
                                })
                            }
                        }
                    }
                    />
            </Row>
            </Col>
            </Row>
            <br/>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col span={5}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={()=> {
                    this.imageManagementSearch()
                }} loading={this.state.ims_search_api_running}
            >
             Search Images
            </Button>
            </Col>
            </Row>
            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                
                <Row style={{overflow: "scroll",justifyContent: 'center',
                                                alignItems: 'center',}}>
                {
                    this.state.ims_search_api_response == null
                    ? (
                        <Empty
                        style={{
                            paddingTop: "5px",
                            width: "100%",
                        }}
                        />
                    )
                    :
                    (
                        <>
                        <Row>
                        <Pagination
                            current={this.state.currentPage}
                            pageSize={this.state.pageSize}
                            total={this.state.images ? this.state.images.length : 0}
                            onChange={(page, pageSize) => {
                                    var offset = (page - 1) * this.state.pageSize;
                                    this.setState({
                                        currentPage: page,
                                        offset: offset,
                                    });
                                }
                            }
                        />
                        <br></br>
                        <br></br>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <Button
                                    onClick={(e) => {
                                        // blacklist all images on the slice of the page
                                        var images = this.state.images;
                                        const X = (this.state.currentPage - 1) * this.state.pageSize; // Starting index
                                        const N = this.state.pageSize; // Number of elements to retrieve
                                        var edited_images_map = this.state.edited_images_map;
                                        for (let i = X; i < X + N && i < this.state.images.length; i++) {
                                            console.log("BALL:i: ", i)
                                            var image_id = images[i]["id"];
                                            edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist
                                            images[i]["status"] = "blacklisted_for_ls";
                                            edited_images_map[image_id]["status"] = "blacklisted_for_ls";
                                        }
                                        this.setState({
                                            images: images,
                                            edited_images_map: edited_images_map,
                                        })
                                    }}
                                >
                                    Blacklist ALL Images for LS
                                </Button>
                            </Col>
                            <Col span={4}>
                                <Button
                                    onClick={(e) => {
                                        // blacklist all images on the slice of the page
                                        var images = this.state.images;
                                        const X = (this.state.currentPage - 1) * this.state.pageSize; // Starting index
                                        const N = this.state.pageSize; // Number of elements to retrieve
                                        var edited_images_map = this.state.edited_images_map;
                                        for (let i = X; i < X + N && i < this.state.images.length; i++) {
                                            console.log("BALL:i: ", i)
                                            var image_id = images[i]["id"];
                                            edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist
                                            images[i]["status"] = "approved";
                                            edited_images_map[image_id]["status"] = "approved";
                                        }
                                        this.setState({
                                            images: images,
                                            edited_images_map: edited_images_map,
                                        })
                                    }}
                                >
                                    Approve ALL Images
                                </Button>
                            </Col>

                        </Row>
                        <Row>
                        {this.state.images.slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize).map((img, img_index) => {
                            return (
                                <Col span={4}>

                                    <Card
                                        hoverable
                                        style={{
                                        width: 240
                                        }}
                                        cover={<img alt="" src={this.state.aws_cdn_prefix+img["id"]+".jpg"} />}
                                    >
                                        <Meta title={img["source_name"]} />
                                        <br/>
                                        {
                                            (img["status"])
                                            ? <Tag color={this.state.statuses_color_options[img["status"]]}>{img["status"]}</Tag>
                                            : <Tag color={"grey"}>NO STATUS</Tag>
                                        }
                                        <br></br>
                                        <p>Last used {this.getDaysSinceLastUsed(img["last_used_at"])} days before</p>
                                        <p>On {img["last_used_at"]}</p>
                                        <h5>Blacklisting reason</h5>
                                        <Input.TextArea
                                            placeholder="Enter Blacklisting reason (Optional)"
                                            onChange={(e)=>{
                                                var images = this.state.images;
                                                images[img_index+this.state.offset]["blacklist_moderator_reason"] = e.target.value
                                                var edited_images_map = this.state.edited_images_map;
                                                var image_id = images[img_index+this.state.offset]["id"];
                                                edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist    
                                                edited_images_map[image_id]["blacklist_moderator_reason"] = e.target.value;
                                                if (e.target.value === "") {
                                                    images[img_index+this.state.offset]["status"] = ""
                                                    edited_images_map[image_id]["status"] = ""
                                                }
                                                else {
                                                    images[img_index+this.state.offset]["status"] = "blacklisted_for_ls"
                                                    edited_images_map[image_id]["status"] = "blacklisted_for_ls"
                                                }
                                                this.setState({
                                                    images: images,
                                                    edited_images_map: edited_images_map,
                                                })
                                            }}
                                            value={
                                                (this.state.images && this.state.images[img_index + this.state.offset])
                                                  ? this.state.images[img_index + this.state.offset]["blacklist_moderator_reason"] || ''
                                                  : ''
                                              }
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                        />
                                        <a
                                            href={this.state.aws_cdn_prefix + img["id"] + ".jpg"}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                        <Button style={{ marginTop: 10 }}>
                                            Download Image
                                        </Button>
                                        </a>

    <Button
          type="text"
          icon={<LikeOutlined style={{ color: img["meta"]?.["is_ls_worthy"] === true ? 'blue' : 'inherit' }} />}
          onClick={(e) => 
            {
                var images = this.state.images;
                var edited_images_map = this.state.edited_images_map;
                var image_id = images[img_index+this.state.offset]["id"];

                // Check if 'meta' exists for the image, if not, create a new object with 'is_ls_worthy'
                if (!images[img_index + this.state.offset]["meta"]) {
                    images[img_index + this.state.offset]["meta"] = {};
                }

                // Update only the 'is_ls_worthy' property of 'meta' without overwriting the entire 'meta' object
                images[img_index + this.state.offset]["meta"]["is_ls_worthy"] = !images[
                    img_index + this.state.offset
                ]["meta"]["is_ls_worthy"];

                edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist
                edited_images_map[image_id]["meta"] = images[img_index+this.state.offset]["meta"];
                this.setState({
                    images: images,
                    edited_images_map: edited_images_map,
                })
            }
        }    
          style={{ 
            marginTop: 10, marginLeft: 10,
            backgroundColor: img["meta"]?.["is_ls_worthy"] === true ? '#3f84f2' : 'transparent',
            color: img["meta"]?.["is_ls_worthy"] === true ? 'white' : 'inherit',
        }}
        >
          Best Quality Image
</Button>


<Checkbox
        onChange={(e) => {
            var images = this.state.images;
            var edited_images_map = this.state.edited_images_map;
            console.log("CHECKBOX::images: ", images)
            console.log("CHECKBOX::img_index: ", img_index)
            console.log("CHECKBOX::this.state.offset: ", this.state.offset)
            console.log("CHECKBOX:edited_images_map: ", edited_images_map)
            var image_id = images[img_index+this.state.offset]["id"];
            console.log("CHECKBOX:image_id: ", image_id)
            edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist
            // if blacklisted or blacklisted_for_ls, mark as approved
            if (images[img_index+this.state.offset]?.status === "blacklisted" || images[img_index+this.state.offset]?.status === "blacklisted_for_ls") {
                images[img_index+this.state.offset].status = "approved"
                edited_images_map[image_id].status = "approved"
            }
            else {
                images[img_index+this.state.offset].status = "blacklisted_for_ls"
                edited_images_map[image_id].status = "blacklisted_for_ls"
            }
            console.log("CHECKBOX:NOW:edited_images_map: ", edited_images_map)
            this.setState({
                images: images,
                edited_images_map: edited_images_map
            })
            console.log("CHECKBOX:state:this.state.edited_images_map: ", this.state.edited_images_map)
        }}
        checked={this.state.images[img_index+this.state.offset]?.status === "blacklisted_for_ls"}
        style={{ marginTop: 10, marginLeft: 10 }}
    >
    <StopOutlined />  Blacklist Image for LS
</Checkbox>

<Checkbox
        onChange={(e) => {
            var images = this.state.images;
            var edited_images_map = this.state.edited_images_map;
            console.log("CHECKBOX::images: ", images)
            console.log("CHECKBOX::img_index: ", img_index)
            console.log("CHECKBOX::this.state.offset: ", this.state.offset)
            console.log("CHECKBOX:edited_images_map: ", edited_images_map)
            var image_id = images[img_index+this.state.offset]["id"];
            console.log("CHECKBOX:image_id: ", image_id)
            edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist
            if (images[img_index+this.state.offset]?.status === "blacklisted") {
                images[img_index+this.state.offset].status = "approved"
                edited_images_map[image_id].status = "approved"
            }
            else {
                images[img_index+this.state.offset].status = "blacklisted"
                edited_images_map[image_id].status = "blacklisted"
            }
            console.log("CHECKBOX:NOW:edited_images_map: ", edited_images_map)
            this.setState({
                images: images,
                edited_images_map: edited_images_map
            })
            console.log("CHECKBOX:state:this.state.edited_images_map: ", this.state.edited_images_map)
        }}
        checked={this.state.images[img_index+this.state.offset]?.status === "blacklisted"}
        style={{ marginTop: 10, marginLeft: 10 }}
    >
    <StopOutlined />  Blacklist Image
</Checkbox>
<br/>
<Select
    mode="multiple"
    placeholder="Blacklist regions"
    style={{
            width: '100%',
            paddingLeft: '5px',
            paddingRight: '5px'
            }}
    value={this.state.images[img_index+this.state.offset]?.blacklist_regions ?? []}
    allowClear={true}
    onChange={(e)=>{
        var images = this.state.images;
        var edited_images_map = this.state.edited_images_map;
        var image_id = images[img_index+this.state.offset]["id"];
        edited_images_map[image_id] = edited_images_map[image_id] || {"id": image_id}; // Create a new object for the outer key if it doesn't exist
        images[img_index+this.state.offset]["blacklist_regions"] = e;
        edited_images_map[image_id]["blacklist_regions"] = e;
        this.setState({
            images: images,
            edited_images_map: edited_images_map
            })
        }}
    >
    {this.state.regions.map(region => {
        return <Option value={region}>{region}</Option>
    })}
</Select>


                                    </Card>
                                    <br />
                            </Col>
                            )
                        })
                    }
                    </Row>
                        </>
                    )
                }
                </Row>
            </Content>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col span={5}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block 
                onClick={this.imsBatchEdit} loading={this.state.ims_batch_edit_api_running}
            >
             Batch Submit Edits
            </Button>
            </Col>
            <Col span={5}>
            <Button rows ={2} type="primary" style={{ width: '100%', marginLeft:'5px' }} block
                onClick={(e) => {
                    this.imsBatchEdit(true)
                }} loading={this.state.ims_batch_edit_api_running}
            >
             Mark Approved Too & Batch Submit Edits
            </Button>
            </Col>
            </Row>
          </Layout>
            </div>
        )
        return (
            <>
                <AuthenticatedTemplate>
            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {
                    this.state.allowed_admin_users.includes(this.props.username)
                    ? createForm
                    : <> You are not an admin user. Please reach out to Ritesh / Pradeep / Uddi for being an admin. Or refresh again?</>
                }
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt=""
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <NoAuth />
        </UnauthenticatedTemplate>
        </>
        )
    }
}
