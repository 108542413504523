import {
    Button, Col, Input, Layout, message, Row, Card, Select, Empty,Modal,Tooltip,Popconfirm,Image, Tag,Checkbox,Pagination
} from 'antd';
import React from 'react';
import {CheckCircleTwoTone} from '@ant-design/icons';
import NavBar from '../components/header';
import {PTMSearchAPI} from '../apis/productCatalogue';
import {UploadButton} from '../components/uploadButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import NoAuth from "../components/noAuth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;

export default class PTMSearch extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            id_token: null,
            input_image_url: null,
            image_upload_loading: false,
            aws_cdn_prefix: process.env.REACT_APP_AWS_PREFIX,
            ptm_search_api_running: false,
            ptm_search_api_response: [],
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,
            image_upload_loading: false,
            images: null,
            gender: null,
            environment: null,
            gender_options: ["Male", "Female"],
            environment_options: ["non-prod","uat", "prod"],
            size: null,
            size_options: ["S", "M", "L", "XL", "XXL"],
            statuses: ["approved", "NONE"],
            statuses_options: ["approved", "blacklisted", "NONE"],
            search_text: null,
            manufacturer_str: null,
            source_name_search_str: null,
            image_url : "",
             // pagination related
             currentPage: 1,
             pageSize: 24,
             offset: 0,
             edited_images_map: {},
        };
        for (var key in this.state.partner_id_options) {
            this.state.partner_id_options_keys.push(key);
            this.state.partner_id_options_values.push(this.state.partner_id_options[key]);
        }

        this.ProductReverseSearch = this.ProductReverseSearch.bind(this);
      }

      getDaysSinceLastUsed(dateString) {
        const lastUsedDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate - lastUsedDate);
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference;
      }

      filterImages() {
        if (!Array.isArray(this.state.images)) {
            console.warn("Props images is not an array", this.state.images);
            return;
        }
    
        let filteredImages = [...this.state.images]; // start with all images
    
        if (this.state.all) {
            console.log("Inside all")
            // if all is selected, use all images without filtering
            this.setState({ images: filteredImages });
            return;
        }
    
        if (this.state.keywords) {
            console.log("Inside keywords")
            filteredImages.sort((a, b) => {
                if (a.elastic_score && b.elastic_score) {
                    return b.elastic_score - a.elastic_score; 
                } else if (a.elastic_score) {
                    return -1;
                } else if (b.elastic_score) {
                    return 1;
                }
                return 0;
            });
        }
    
        if (this.state.uploaded) {
            console.log("Inside uploaded")
            filteredImages = filteredImages.filter(img => img.spec && img.spec.is_uploaded === true);
        }
    
        this.setState({ images: filteredImages });
        console.log("Filtered images",this.state.images)
    }

    copyToClipboard = (id) => {
        navigator.clipboard.writeText(id || "N/A");
      };

      ProductReverseSearch = async () => {
        message.loading("Fetching Images..", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = {
                    
                    "spec" : {},
            }
            if (this.state.image_url && this.state.search_text) {
                payload["image_url"] = this.state.image_url; // Prioritize image_url if both exist
            } else if (this.state.search_text) {
                payload["image_url"] = this.state.search_text; // Send search_text as image_url
            } else if (this.state.image_url) {
                payload["image_url"] = this.state.image_url; // Send only image_url
            } else {
                // If neither is provided, show error and exit
                message.destroy();
                message.error("Please provide an image or search text.");
                return;
            }
            if (this.state.gender) {
                payload["gender"] = this.state.gender
            }
            if (this.state.environment) {
                payload["environment"] = this.state.environment
            }
 
            PTMSearchAPI(payload, token).then((response) => {
                if(response.status===false){
                    message.destroy()
                    message.error(response.message["detail"]+". " + response.status_text+". Login again or new tab :)")
                }else{
                    message.destroy()
                    message.success("Image Search Complete!")
                }
                this.setState({
                    ptm_search_api_running: false,
                    ptm_search_api_response: response.message,
                    images: response.message.images,

                }, () => {
                    // Callback function after state update
                    this.filterImages();
                });
               
            }).catch ((error) => {
                message.destroy()
                message.error("Failed to fetch images. Try again?")
                this.setState({
                    ptm_search_api_running: false,
                })
            })
        })
    }

    componentDidMount(){
        // DO NOTHING HERE
    }

    render() {
        
        const createForm = (
            <div>
                <Layout>
            <Content>
            <Row>
                <h2>Product Search Portal</h2>
            </Row>

            <Row gutter={[16, 16]}>
            <Col span={3}>
                <h3>Reference Image</h3>
                <UploadButton
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e) => {
                        if (e.file.status === 'done') {
                            message.success('Uploaded!')
                            var temp_video_upload_url = e.file.response.image_url
                            this.setState({
                                image_url: temp_video_upload_url
                            })
                        } else if (e.file.status === 'removed') {
                            this.setState({
                                image_url: ''
                            })
                        }
                    }}
                />
            </Col>
            <Col span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#8c8c8c',
                    border: '1px solid #d9d9d9',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#f5f5f5',
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
                }}>
                    OR
                </div>
            </Col>

            <Col span={8}>
                <h3>Search Text</h3>
                <Tooltip trigger={['focus']} title={"Any specific source"} placement="topLeft">
                    <Input placeholder='Search Text...'
                        onChange={(e) => {
                            this.setState({
                                search_text: e.target.value
                            })
                        }}
                    />
                </Tooltip>
            </Col>

            <Col span={4}>
            <h3>Gender</h3>
                <Tooltip trigger={['focus']} title={"Select gender"} placement="topLeft">
                    <Select
                        placeholder="Select gender"
                        style={{ width: '100%' }}
                        allowClear={true}
                        onChange={(value) => {
                            this.setState({
                                gender: value
                            });
                        }}
                    >
                        {this.state.gender_options.map(option => {
                            return <Option value={option} key={option}>{option}</Option>;
                        })}
                    </Select>
                </Tooltip>
            </Col>

            <Col span={4}>
            <h3>Environment</h3>
                <Tooltip trigger={['focus']} title={"Select Environment"} placement="topLeft">
                    <Select
                        placeholder="Select Environment"
                        style={{ width: '100%' }}
                        allowClear={true}
                        onChange={(value) => {
                            this.setState({
                                environment: value
                            });
                        }}
                    >
                        {this.state.environment_options.map(option => {
                            return <Option value={option} key={option}>{option}</Option>;
                        })}
                    </Select>
                </Tooltip>
            </Col>

            <Col span={4}>
                <h3>&nbsp;</h3>
                <Button type="primary" style={{ width: '100%' }} block
                    onClick={this.ProductReverseSearch} loading={this.state.ptm_search_api_running}>
                    Search Images
                </Button>
            </Col>
            {this.state.image_url && (
                <Col span={4}>
                    <h3>Uploaded Image</h3>
                    <Image
                        width={100}
                        src={this.state.image_url}
                        alt="Uploaded Image"
                        style={{ marginTop: '10px' }}
                    />
                </Col>
            )}
        </Row>

            <br>
            </br>
            </Content>
            <br></br>
            <br></br>
            <Content>
                    <Row style={{ overflow: "scroll", justifyContent: 'center', alignItems: 'center' }}>
                            {
                                !this.state.ptm_search_api_response || this.state.ptm_search_api_response.length === 0 ? (
                                    <Empty
                                        style={{
                                            paddingTop: "5px",
                                            width: "100%",
                                        }}
                                    />
                                ) : (
                                    <>
                                        <Row>
                                            <Pagination
                                                current={this.state.currentPage}
                                                pageSize={this.state.pageSize}
                                                total={this.state.images ? this.state.images.length : 0}
                                                onChange={(page, pageSize) => {
                                                    var offset = (page - 1) * pageSize;
                                                    this.setState({
                                                        currentPage: page,
                                                        offset: offset,
                                                    });
                                                }}
                                            />
                                            <br />
                                            <br />
                                        </Row>
                                        <Row gutter={[16, 16]} justify="center">
                                        {this.state.ptm_search_api_response.map((product, productIndex) => {
                                            return (
                                                <Row gutter={[16, 16]} key={`product-${productIndex}`}>
                                                    {/* Column for Extracted Product */}
                                                    <Col
                                                        xs={24}  // Full width on extra small screens
                                                        sm={12}  // 2 columns per row on small screens
                                                        md={8}   // 1/3 of the row width on medium screens
                                                        lg={6}   // 1/4 of the row width on large screens
                                                        xl={4}   // 1/5 of the row width on extra-large screens
                                                    >
                                                        <Card
                                                            hoverable
                                                            style={{ width: '100%' }}
                                                            cover={<img alt="" src={product.product_url} />}
                                                        >
                                                            <Meta title={`Product: ${product.product_name || "N/A"}`} />
                                                            <br />
                                                            <p>Extracted Product</p>
                                                            <p>Product URL: <a href={product.product_url} target="_blank" rel="noreferrer">View Product</a></p>
                                                        </Card>
                                                    </Col>

                                                    {/* Column for Similar Products */}
                                                    <Col
                                                        xs={24}  // Full width on extra small screens
                                                        sm={24}  // Full width on small screens
                                                        md={16}  // Remaining width for similar products on medium screens
                                                        lg={18}  // Remaining width for similar products on large screens
                                                        xl={20}  // Remaining width for similar products on extra-large screens
                                                    >
                                                        <Row gutter={[16, 16]} justify="start">
                                                            {product.similar_products.slice(0, 12).map((similarProduct, index) => {
                                                                return (
                                                                    <Col
                                                                        xs={24}  // Full width for each product on extra small screens
                                                                        sm={12}  // 2 columns per row on small screens
                                                                        md={10}   // 3 columns per row on medium screens
                                                                        lg={8}   // 4 columns per row on large screens
                                                                        xl={6}   // 5 columns per row on extra-large screens
                                                                        key={`similar-product-${index}`}
                                                                    >
                                                                        <Card
                                                                            hoverable
                                                                            style={{ width: '100%' }}
                                                                            cover={
                                                                                <img
                                                                                    alt=""
                                                                                    src={similarProduct.metadata.imageUrl}
                                                                                    onError={(e) => { e.target.src = 'fallback_image_url'; }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <Meta title={similarProduct.metadata.name || "Unnamed Product"} />
                                                                            <p>Gender: {similarProduct.metadata.gender}</p>
                                                                            <p>Category: {similarProduct.metadata.category}</p>
                                                                            <p>Size: {similarProduct.metadata.size || "N/A"}</p>
                                                                            <p>Price: {similarProduct.metadata.price || "N/A"} {similarProduct.metadata.PriceCurrency} </p>
                                                                            <p>Score: {similarProduct.score.toFixed(2)}</p>
                                                                            <div className="flex items-center space-x-2">
                                                                                <p className="truncate max-w-xs" title={similarProduct.id || "N/A"}>
                                                                                    ID: {similarProduct.id || "N/A"}
                                                                                </p>
                                                                                <button
                                                                                    onClick={() => this.copyToClipboard(similarProduct.id)}
                                                                                    className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                                                                                >
                                                                                    Copy
                                                                                </button>
                                                                            </div>
                                                                        </Card>
                                                                    </Col>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Row>


                                    </>
                                )
                            }
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
            <Layout style={{'min-height':'100vh'}}>
            <NavBar
                authHandler={async (instance) => {await this.authHandler(instance)}}
                authRefresh={this.authRefresh}
                signin_name={this.props.username}
            />
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                {createForm}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            <img
                src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                alt=""
                style={{
                    height:'20px',
                    display:'inline',
                    paddingRight:'10px'
                }}/>
                 ©2021 Created by TAG AI Team</Footer>
          </Layout>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <NoAuth />
        </UnauthenticatedTemplate>
        </>
        )
    }
}
