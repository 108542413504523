import {
    Button, Col, Input, Layout, message, Row, Typography, Select, DatePicker, Space, Popconfirm
} from 'antd';
import React from 'react';
import moment from "moment";
import NavBar from '../components/header';
import { AmpStoryViewAPI, AmpStoryUpdateAPI, AmpStorySearchAPI, AmpStoryPublishAPI, AmpStoryDeleteAPI } from "../apis/ampStory";
import { IMSV2ImageSelectionAPI} from "../apis/vcs";
import { MultiSelectDropDown } from "../components/multiSelectDropDown"
import { LanguageDropDown } from "../components/languageDropDown"
import { AmpEditorModal } from "../components/ampEditorModal"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { IMSImageSearchModal } from "../components/imsImageSearchModal";
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { Text } = Typography;


export default class AmpStoryCreate extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil,"
            ],
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,

            image_search_modal_open: false,
            ims_selected_image_id: null,
            ims_selected_image_source: null,
            ims_banner_image_url: null,
            image_ids: [],
            image_id_to_source_map: {},
            amp_editor_modal_open: false,
            amp_editor_editing_pages: null,

            amp_story_search_api_running: false,
            amp_story_search_response: null,
            search_categories: [],
            search_language: "en",
            search_modified_date: new Date(),
            search_skip: 0,
            search_limit: 100,

            selected_amp_story_index: null,

            amp_story_publish_api_running: false,
            amp_story_publish_response: null,

            amp_story_delete_api_running: false,
            amp_story_delete_response: null,

            current_page_index: 0,
            target_language: "en",
            amp_story_api_running: false,
            amp_story_api_response: null,
            amp_story_update_api_running: false,
            amp_story_update_response: null,
            source_url: null,
            num_points: 7,
            show_heading: true,
            stage: null,

            amp_story: null,
            amp_story_html: "<p>AMP story preview will be shown here</p>",
            amp_story_html_url: null,
            amp_story_publish_url: process.env.REACT_APP_TRENDS_BASE_URL + "/newz/amp",  // TODO: agree this with Engg

            content_category: "NEWS",
            category: "national_v_two",
            category_options: [
                'daily_digest',
                'cricket',
                'sports',
                'entertainment',
                'technology',
                'travel_and_lifestyle',
                'automotive',
                'food_and_health',
                'national_v_two',
                'international',
                'business',
                'fashion',
                'music',
                'fun_facts',
                'games',
                'nature',
                'talent',
                'comedy',
                'tamil_news',
                'telugu_news',
                'kannada_news',
                'marathi_news',
                'bengali_news',
                'home_n_living',
                'celebrity',
                'education',
                'live_game_streams',
                'religion',
                'content_for_apps',
                'ipl',
            ],
           
        };
        
        this.updateAmpStory = this.updateAmpStory.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleAmpEditorModal = this.toggleAmpEditorModal.bind(this);

    }

    onMovePage = (direction, index) => {
        let pages = [...this.state.amp_editor_editing_pages];

        if (direction === "up" && index > 0) {
            [pages[index - 1], pages[index]] = [pages[index], pages[index - 1]];
        } else if (direction === "down" && index < pages.length - 1) {
            [pages[index], pages[index + 1]] = [pages[index + 1], pages[index]];
        }

        this.setState({ amp_editor_editing_pages: pages });
    };
    searchAmpStories = async () => {
        if (this.state.search_categories.length == 0) {
            message.destroy()
            message.info("Select at least one category!")
            return
        }
        if (this.state.search_modified_date == null) {
            message.destroy()
            message.info("Select a modified date!")
            return
        }

        message.loading("Searching AMP stories...", 0)
        this.setState({
            amp_story_search_api_running: true,
            amp_story_search_response: null,
            amp_story_html: "<p>AMP story preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload = {
                categories: this.state.search_categories,
                language: this.state.search_language,
                modified_date: this.state.search_modified_date,
                skip: this.state.search_skip,
                limit: this.state.search_limit
            }
            AmpStorySearchAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AMP story search API failed!")
                    this.setState({
                        amp_story_search_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("AMP story search API success!")
                    this.setState({
                        amp_story_search_api_running: false,
                        amp_story_search_response: response.message.stories,
                        amp_story: null,
                        selected_amp_story_index: null
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to search AMP stories!")
                this.setState({
                    amp_story_search_api_running: false,
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to search AMP stories!", error)
            this.setState({
                amp_story_search_api_running: false,
            })
        })
    }


    updateAmpStory = async () => {
        message.loading("Updating AMP story view...", 0)
        this.setState({
            amp_story_update_api_running: true,
            amp_story_api_response: null
        })

        this.props.refreshIdToken().then((token) => {
            const payload_view = {
                story: this.state.amp_story,
                ads_config: null
            }
            AmpStoryViewAPI(payload_view, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AMP story view API failed!")
                    this.setState({
                        amp_story_update_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("AMP story view updated!")
                    var story = this.state.amp_story
                    story.html_url = response.message.amp_story_html_url
                    this.setState({
                        amp_story_update_api_running: false,
                        amp_story_html: response.message.amp_story_html,
                        amp_story_html_url: response.message.amp_story_html_url,
                        amp_story: story
                    })

                    const payload_create = {
                        story: this.state.amp_story,
                    }
                    AmpStoryUpdateAPI(payload_create, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("AMP story database update API failed!")
                            this.setState({
                                amp_story_api_running: false,
                            })
                        } else {
                            message.destroy()
                            message.success("AMP story updated in database!")
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to update AMP story in database!")
                        this.setState({
                            amp_story_update_api_running: false,
                        })
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to update AMP story view!")
                this.setState({
                    amp_story_update_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to update AMP story!", error)
            this.setState({
                amp_story_update_api_running: false
            })
        })
    }


    publishAmpStory = async () => {
        message.loading("Publishing AMP story...", 0)
        this.setState({
            amp_story_publish_api_running: true,
            amp_story_publish_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            // publish AMP story in DB
            AmpStoryPublishAPI(this.state.amp_story.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AMP story publish API failed!")
                    this.setState({
                        amp_story_publish_api_running: false,
                        amp_story_publish_response: response.message
                    })
                } else {
                    message.destroy()
                    message.success("AMP story published!")
                    var amp_story_search_response = this.state.amp_story_search_response
                    amp_story_search_response[this.state.selected_amp_story_index] = response.message.story
                    this.setState({
                        amp_story_publish_api_running: false,
                        amp_story_publish_response: response.message,
                        amp_story: response.message.story,
                        amp_story_search_response: amp_story_search_response
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish AMP story!")
                this.setState({
                    amp_story_publish_api_running: false
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to publish AMP story!", error)
            this.setState({
                amp_story_publish_api_running: false
            })
        })
    }


    deleteAmpStory = async () => {
        message.loading("Deleting AMP story...", 0)
        this.setState({
            amp_story_delete_api_running: true,
            amp_story_delete_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            // delete AMP story from the DB
            const payload = {
                story: this.state.amp_story,
            }
            AmpStoryDeleteAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("AMP story delete API failed!")
                    this.setState({
                        amp_story_delete_api_running: false,
                        amp_story_delete_response: response.message
                    })
                } else {
                    message.destroy()
                    message.success("AMP story deleted!")

                    // remove the deleted story from the search results
                    var stories = this.state.amp_story_search_response;
                    var index = stories.indexOf(this.state.amp_story);
                    if (index > -1) {
                        stories.splice(index, 1);
                    }

                   this.setState({
                        amp_story_delete_api_running: false,
                        amp_story_delete_response: response.message,
                        amp_story: null,
                        amp_story_search_response: stories,
                        selected_amp_story_index: null
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to delete AMP story!")
                this.setState({
                    amp_story_delete_api_running: false
                })
            })

        }).catch((error) => {
            message.destroy()
            message.error("Failed to delete AMP story!", error)
            this.setState({
                amp_story_delete_api_running: false
            })
        })
    }


    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    "description": "",
                    "max_count": 30,
                    "filter_images": false
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Image Selection API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if (response.status == true) {
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source_name;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        selected_image_id: image_ids[0],
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids)
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleAmpEditorModal = () => {
        this.setState({
            amp_editor_modal_open: !this.state.amp_editor_modal_open
        })
    }

    render() {
        console.log("AMP story: ", this.state.amp_story)
        const createForm = (
            <div>
                <IMSImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={(e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        pages[this.state.current_page_index].image_url = this.state.ims_banner_image_url;
                        pages[this.state.current_page_index].image_source = this.state.ims_selected_image_source;
                        this.setState({
                            amp_editor_editing_pages: pages
                        })
                        this.toggleImageSearchModal()
                    }}
                    imageSelection={this.imageSelection}
                    default_value={this.state.search_text}
                    image_list={this.state.image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e)=>{
                        this.setState({
                            ims_selected_image_id: e.target.value,
                            ims_selected_image_source: this.state.image_id_to_source_map[e.target.value]?? "",
                            ims_banner_image_url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        })
                    }}
                    selectedImage={this.state.ims_banner_image_url}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e) => {
                        if (e.file.status == 'done') {
                            var pages = this.state.amp_editor_editing_pages;
                            pages[this.state.current_page_index].image_url = e.file.response.image_url;
                            pages[this.state.current_page_index].image_source = "";
                            this.setState({
                                amp_editor_editing_pages: pages,
                                image_search_modal_open: false
                            })
                            message.success('Image Uploaded!')                        }
                    }}
                />

                <AmpEditorModal
                    onChangeOrder={this.handleChangeOrder}
                    isOpen={this.state.amp_editor_modal_open}
                    centered
                    onOk={(e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        var story = this.state.amp_story;
                        story.pages = pages;
                        this.setState({
                            amp_story: story
                        })
                        this.updateAmpStory()
                        this.toggleAmpEditorModal()
                    }}
                    onCancel={this.toggleAmpEditorModal}
                    onClose={this.toggleAmpEditorModal}
                    onDeletePage={(e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        pages.splice(e, 1)
                        this.setState({
                            amp_editor_editing_pages: pages
                        })
                    }}
                    onChange={(field_name, page_index, e) => {
                        var pages = this.state.amp_editor_editing_pages;
                        pages[page_index][field_name] = e.target.value
                        this.setState({
                            amp_editor_editing_pages: pages
                        })
                    }}
                    onReplaceImage={(page_index) => {
                        var pages = this.state.amp_editor_editing_pages;
                        this.setState({
                            image_ids: pages[page_index].image_ids,
                            ims_banner_image_url: pages[page_index].image_url,
                            current_page_index: page_index
                        })
                    }}
                    toggleImageSearchModal={this.toggleImageSearchModal}
                    editing_pages={this.state.amp_editor_editing_pages}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                />
                
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3>Search for AMP Stories</h3>
                        <Row style={{ width: '100%' }}>
                            <Col span={15}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Categories
                                </Text>
                                <br />
                                <MultiSelectDropDown
                                    options={this.state.category_options}
                                    onChange={(e) => {
                                        this.setState({
                                            search_categories: e
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={2} style={{ paddingLeft: '20px'}}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Language
                                </Text>
                                <br />
                                <LanguageDropDown
                                    language={this.state.target_language}
                                    language_options={this.state.lang_options}
                                    onLanguageSelect={(e) => {
                                        console.log("onLanguageSelect - " + e)
                                        this.setState({
                                            search_language: e
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={4} style={{ paddingLeft: '20px'}}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Modified Date
                                </Text>
                                <br />
                                <Space direction="vertical">
                                    <DatePicker
                                        defaultValue={moment(this.state.search_modified_date)}
                                        onChange={(e) => {
                                            this.setState({
                                                search_modified_date: e
                                            })
                                        }}
                                    />
                                </Space>

                            </Col>
                            <Col span={3}>
                                <Button type="primary" block
                                    style={{
                                        marginTop: '21px'
                                    }}
                                    onClick={this.searchAmpStories} loading={this.state.amp_story_search_api_running}
                                >
                                    Search 
                                </Button>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    Search Results
                                </Text>
                                <br />
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder={this.state.amp_story_search_response == null ? "Search for AMP stories" : "Select an AMP story:"}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.setState({
                                            amp_story: this.state.amp_story_search_response[e],
                                            selected_amp_story_index: e
                                        })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={this.state.selected_amp_story_index}
                                >
                                    {this.state.amp_story_search_response ? (
                                        this.state.amp_story_search_response.map((story, index) => {
                                            return (
                                                <Select.Option value={index}>{"[" + story.stage + "] " + story.pages[0].text}</Select.Option>
                                            )
                                        })
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Select>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row style={{ visibility: this.state.amp_story == null ? 'hidden' : 'visible'}}>
                            <Col span={12}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    AMP Story Preview
                                </Text>
                                <br />
                                <iframe

                                    style={{
                                        width: '100%',
                                        height: '640px'
                                    }}
                                    title="view"
                                    src={this.state.amp_story && 'html_url' in this.state.amp_story ? this.state.amp_story.html_url : ""}/>
                            </Col>
                            <Col span={12} style={{ paddingLeft: '40px'}}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    AMP Story Details
                                </Text>
                                <br />
                                <Row span={24}>
                                    <table style={{ lineHeight: '2.5em'}}>
                                        <tr>
                                            <td><b>Title:</b></td>
                                            <td style={{ lineHeight: '1.3em'}}>{this.state.amp_story && 'pages' in this.state.amp_story ? this.state.amp_story.pages[0].text : ""}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Category:</b></td>
                                            <td>{this.state.amp_story && 'category' in this.state.amp_story ? this.state.amp_story.category : ""}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: '20px' }}><b>Language:</b></td>
                                            <td>{this.state.amp_story && 'target_language' in this.state.amp_story ? this.state.amp_story.target_language : ""}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Pages:</b></td>
                                            <td>{this.state.amp_story && 'pages' in this.state.amp_story ? this.state.amp_story.pages.length : ""}</td>
                                        </tr>
                                        <tr>
                                            <td><b>State:</b></td>
                                            <td>{this.state.amp_story && 'stage' in this.state.amp_story ? this.state.amp_story.stage : ""}</td>
                                        </tr>
                                    </table>
                                </Row>
                                <Row span={24}>
                                    <Col span={13}>
                                        <br />
                                        <br />
                                        <Text style={{ fontWeight: 'bold' }}>
                                            AMP Story Actions
                                        </Text>
                                        <br />
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                var pages = this.state.amp_story.pages;
                                                this.setState({
                                                    amp_editor_editing_pages: pages
                                                }, () => {
                                                    this.toggleAmpEditorModal()
                                                })
                                            }}
                                            style={{
                                                width: 290,
                                                overflow: 'clip'
                                            }}
                                            block
                                            loading={this.state.amp_story_update_api_running || this.state.amp_story_api_running}
                                            disabled={
                                                this.state.amp_story === null
                                                || (this.state.amp_story && this.state.amp_story.stage == "published")
                                                || this.state.amp_story_publish_api_running
                                                || this.state.amp_story_delete_api_running
                                            }
                                        >
                                            Edit and Update AMP Story
                                        </Button>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Do you really want to publish this AMP story? Editing will not be possible after publishing."
                                            onConfirm={(e) => {
                                                this.publishAmpStory()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 290,
                                                    marginTop: '20px',
                                                    display: 'block'
                                                }}
                                                loading={this.state.amp_story_publish_api_running}
                                                disabled={
                                                    this.state.amp_story === null
                                                    || (this.state.amp_story && this.state.amp_story.stage == "published")
                                                    || this.state.amp_story_api_running
                                                    || this.state.amp_story_update_api_running
                                                }
                                            >
                                                Publish AMP Story WITHOUT Chaining
                                            </Button>
                                        </Popconfirm>
                                        <Popconfirm
                                            title="Do you really want to publish this AMP story with Chaining? Editing will not be possible after publishing."
                                            onConfirm={(e) => {
                                                this.publishAmpStoryWithChaining()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 290,
                                                    marginTop: '20px',
                                                    display: 'block'
                                                }}
                                                loading={this.state.amp_story_publish_api_running}
                                                disabled={
                                                    this.state.amp_story === null
                                                    || (this.state.amp_story && this.state.amp_story.stage == "published")
                                                    || this.state.amp_story_api_running
                                                    || this.state.amp_story_update_api_running
                                                }
                                            >
                                                Publish AMP Story With Chaining
                                            </Button>
                                        </Popconfirm>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Delete this AMP story ?"
                                            onConfirm={(e) => {
                                                this.deleteAmpStory()
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 290,
                                                    overflow: 'clip'
                                                }}
                                                block
                                                loading={this.state.amp_story_delete_api_running}
                                                disabled={
                                                    this.state.amp_story === null
                                                    || (this.state.amp_story && this.state.amp_story.stage == "published")
                                                    || this.state.amp_story_update_api_running
                                                    || this.state.amp_story_api_running
                                                    || this.state.amp_story_publish_api_running
                                                }
                                            >
                                                Delete AMP Story
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                    <Col span={11} style={{ paddingLeft: "20px"}}>
                                    <br />
                                        <br />
                                        <Row>
                                            <Button
                                                style={{
                                                    marginTop: '22px',
                                                    display: 'block'
                                                }}
                                                onClick={() => navigator.clipboard.writeText(this.state.amp_story.html_url)}
                                                disabled={
                                                    this.state.amp_story === null
                                                    || this.state.amp_story_api_running
                                                    || this.state.amp_story_update_api_running
                                                    || this.state.amp_story_publish_api_running
                                                    || this.state.amp_story_delete_api_running
                                                }
                                        >
                                                COPY AMP PREVIEW URL
                                            </Button>
                                        </Row>
                                        {/* {this.state.amp_story && this.state.amp_story.stage == "published" ? (
                                            <Row>
                                                <Button 
                                                    style={{
                                                        marginTop: '42px',
                                                        display: 'block'
                                                    }}
                                                    onClick={() => navigator.clipboard.writeText(this.state.amp_story_publish_url + "/" + this.state.amp_story.id)}
                                                    disabled={
                                                        this.state.amp_story === null
                                                        || this.state.amp_story_api_running
                                                        || this.state.amp_story_update_api_running
                                                        || this.state.amp_story_publish_api_running
                                                        || this.state.amp_story_delete_api_running
                                                    }
                                                >
                                                    COPY AMP PUBLISHED URL
                                                </Button>
                                            </Row>
                                        ) : (
                                            <></>
                                        )} */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
